import styled from 'styled-components';
import { decimalToKr } from '../../functions';
import { IInvoice } from '../../interfaces';
import { useGlobalState } from '../../state';
import Dot from '../Dot/Dot';
import { Line, RowView, Text } from '../../helpers';
import RoundBox from '../RoundBox/RoundBox';
import ICheckoutCost from '../../interfaces/CheckoutCost/CheckoutCost.interface';

type CustomerDetailsProps = {
    checkoutCost: ICheckoutCost;
    status?: string;
};

type WrapperProps = {
    status?: string;
};

const Wrapper = styled.div<WrapperProps>`
    margin-top: ${(props) => (props.status === 'failed' ? '3vh' : '8vh')};
`;

const EndCustomer = styled(Text)`
    /* margin-bottom: 1vh; */
`;

const CustomerDetails = ({ checkoutCost, status }: CustomerDetailsProps) => {
    const [invoice] = useGlobalState('invoice');

    const organization = invoice.organization;
    const lines = invoice.lines;

    return (
        <Wrapper status={status}>
            <RoundBox>
                <EndCustomer i={organization.name} fontWeight="bold" header />
                <RowView
                    style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                    <Text i={`Fakturaunderlag #${invoice.invoice_number}`} />
                    <Line />
                </RowView>
                {lines.map((item, index) => (
                    <div key={index}>
                        <RowView style={{ gap: '10px' }}>
                            <Text i={item.description} />
                            <Text
                                i={decimalToKr(item.amount_excl_vat)}
                                style={{ textWrap: 'nowrap' }}
                            />
                        </RowView>
                    </div>
                ))}
                <Line />
                <RowView style={{ marginTop: 20 }}>
                    <Text i={`Moms ${invoice.vat_rate}%`} />
                    <Text i={decimalToKr(invoice.vat)} style={{ textWrap: 'nowrap' }} />
                </RowView>
                {Number(checkoutCost.cost_excl_vat_amount) > 0 && (
                    <>
                        <Line />
                        <RowView>
                            <Text i={`Checkout ${invoice.due_days}`} />
                            <Text
                                i={decimalToKr(Number(checkoutCost.cost_excl_vat_amount))}
                                style={{ textWrap: 'nowrap' }}
                            />
                        </RowView>

                        <RowView>
                            <Text
                                i={`Checkout ${invoice.due_days} Moms ${(
                                    (Number(checkoutCost.vat_cost) /
                                        Number(checkoutCost.cost_excl_vat_amount)) *
                                    100
                                ).toFixed(0)}%`}
                            />
                            <Text
                                i={decimalToKr(Number(checkoutCost.vat_cost))}
                                style={{ textWrap: 'nowrap' }}
                            />
                        </RowView>
                    </>
                )}
                <RowView style={{ marginTop: '2vh' }}>
                    <Text i="Att betala" fontWeight="bold" />
                    <Text
                        i={decimalToKr(
                            Number(invoice.total_amount) +
                                Number(checkoutCost.cost_excl_vat_amount) +
                                Number(checkoutCost.vat_cost)
                        )}
                        fontWeight="bold"
                        style={{ textWrap: 'nowrap' }}
                    />
                </RowView>
            </RoundBox>
        </Wrapper>
    );
};

export default CustomerDetails;
