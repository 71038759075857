import styled from 'styled-components';
import { ReactElement } from 'react';
import { DicoLogo } from '../Logos';
import background from '../../assets/images/background_image.png';

type LogoProps = {
    width?: string;
    height?: string;
};

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 140px;
    background: linear-gradient(116deg, #ed1e79, #9e005d);
    /* background-color: #67182e; */
    background-position: 50% 50%;
    background-size: cover;
    z-index: -1;
`;

const BackgroundImage = styled.div`
    width: 100%;
    height: 300px;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 13px;
    margin-bottom: 13px;
    margin-left: 25px;
    height: 52px;
`;

const DicopayLogo = ({ width = '122', height = '38px' }: LogoProps) => {
    return (
        <LogoWrapper>
            <DicoLogo width={width} height={height} />
        </LogoWrapper>
    );
};

const Header = (): ReactElement => (
    <Wrapper>
        <DicopayLogo width="150" />
        <BackgroundImage />
    </Wrapper>
);

export default Header;
