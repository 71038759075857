import { useParams, useLocation } from 'react-router-dom';
import { useGetInvoice } from '../../api';
import {
    AnimatedLoader,
    CustomerDetails,
    Error,
    UserInfo,
    PaymentMethods,
    DueDays,
    InvoiceDetails
} from '../../components';
import { Clamp } from '../../helpers';
import { useGlobalState } from '../../state';
import InvoiceStatus from '../../components/InvoiceStatus/InvoiseStatus';
import MobileAppsButtons from '../../components/MobileAppsButtons/MobileAppsButtons';
import { useState } from 'react';
import ICheckoutCost from '../../interfaces/CheckoutCost/CheckoutCost.interface';

const Checkout = () => {
    const { invoice_id } = useParams();
    const { fetchedInvoice } = useGetInvoice(invoice_id);
    const [invoice] = useGlobalState('invoice');
    const location = useLocation();
    const [checkoutCost, setCheckoutCost] = useState<ICheckoutCost>({
        cost_excl_vat_amount: 0,
        vat_cost: 0
    });

    // Renders components after backend redirection
    if (location.state?.failed === 'redirected') {
        return (
            <>
                {invoice.is_signed ? (
                    <>
                        {invoice ? (
                            <Clamp>
                                <CustomerDetails checkoutCost={checkoutCost} />
                                <InvoiceStatus status="success" />
                            </Clamp>
                        ) : (
                            <AnimatedLoader />
                        )}
                    </>
                ) : (
                    <Clamp>
                        <InvoiceStatus status="failed" />
                        <CustomerDetails checkoutCost={checkoutCost} status="failed" />
                        {/* <PaymentMethods /> */}
                        {/* <BankIDButton /> */}
                        <DueDays
                            invoiceId={invoice.uuid}
                            checkoutCost={checkoutCost}
                            setCheckoutCost={setCheckoutCost}
                        />
                        <InvoiceDetails />
                    </Clamp>
                )}
            </>
        );
    }

    // Render Error component in case of no invoice
    if (fetchedInvoice && invoice.is_signed === null) {
        return (
            <>
                <Error message="Invoice not found" />
            </>
        );
    }

    if (fetchedInvoice && invoice.status === 'cancelled')
        return <Error invoice={invoice} message="This invoice is cancelled" reason="cancelled" />;

    // Render components depends on "is_signed" field of invoice.

    switch (invoice.is_signed) {
        case true:
            return (
                <>
                    {invoice ? (
                        <Clamp>
                            <InvoiceStatus status="success" />
                            <CustomerDetails checkoutCost={checkoutCost} />
                            <MobileAppsButtons />
                        </Clamp>
                    ) : (
                        <AnimatedLoader />
                    )}
                </>
            );
        case false:
            return (
                <>
                    {invoice ? (
                        <Clamp>
                            <CustomerDetails checkoutCost={checkoutCost} />

                            <DueDays
                                invoiceId={invoice.uuid}
                                checkoutCost={checkoutCost}
                                setCheckoutCost={setCheckoutCost}
                            />
                            <UserInfo />
                        </Clamp>
                    ) : (
                        <AnimatedLoader />
                    )}
                </>
            );
        default:
            <Error message="Something went worng" />;
    }

    return <></>;
};

export default Checkout;
