import React from 'react';
import styled from 'styled-components';

type AppStoreButtonPropsType = {
    width?: string;
    height?: string;
};

const Wrapper = styled.div<AppStoreButtonPropsType>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;

const AppStoreButton = ({ width, height = 'auto' }: AppStoreButtonPropsType) => {
    return <Wrapper width={width} height={height}></Wrapper>;
};

export default AppStoreButton;
