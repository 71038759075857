import CancelIcon from '@mui/icons-material/Cancel';
import RoundBox from '../RoundBox/RoundBox';
import { Text } from '../../helpers';
import { ReactElement } from 'react';

const InvoiceFailed = (): ReactElement => {
    return (
        <>
            <RoundBox
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                    alignItems: 'center',
                    marginTop: '4vh',
                    padding: '12px'
                }}>
                <>
                    <CancelIcon fontSize="large" style={{ fill: 'red' }} />
                    <Text
                        i="Processen att underteckna faktura misslyckades"
                        fontWeight="600"
                        color="red"
                        style={{
                            textAlign: 'center'
                        }}
                    />
                </>
            </RoundBox>
        </>
    );
};

export default InvoiceFailed;
