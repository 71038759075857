import React from 'react';
import { RowView, Text } from '../../helpers';
import { colors } from '../../assets/colors';

type PaymentProviderProps = {
    logoSrc: string;
    style: React.CSSProperties;
};

const PaymentProvider = ({ logoSrc, style }: PaymentProviderProps) => {
    return (
        <RowView style={{ justifyContent: 'center', flexWrap: 'wrap' }}>
            <Text i="Betallösningen hanteras av " color={colors.dark} />
            <img alt="Invoier logo" style={{ ...style }} src={logoSrc} />
        </RowView>
    );
};

export default PaymentProvider;
